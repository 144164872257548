import * as React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import NoMatch from '../../404Old';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../../utils/seo/Index';
import { Container } from '../../../layout/Container';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { useLocation, useParams } from 'react-router';
import { Section } from '../../../layout/Section';

//GraphQL
import { useMutation, useQuery } from '@apollo/client';
import { Checkbox } from '../../../ui/form/Checkbox';
import { Form, FormRow, FormWrapper } from '../../../ui/form/Form';
import { useForm } from '../../../../utils/hooks/useForm';
import { useDigtectiveContext } from '../../../../__lib__/digtective/context/hooks/useDigtectiveContext';
import { ILeadInput, ILeadResponse } from '../../../../__types__/global';
import { useFormTransition } from '../../../../utils/hooks/useFormTransition';
import { createGTMEvent } from '../../../../utils/tracking/gtm';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { routeConfig } from '../../../../__config__/routes';
import { Input } from '../../../ui/form/Input';
import { FormError } from '../../../ui/form/FormError';
import { Thanks } from '../../../layout/Thanks';
import { AccordionGeneral } from '../../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../../partials/AccordionBenefits';
import { createLeadMutation } from '../../../../../client/__graphql__/mutations';
import { QUIZ_PAGE } from '../../../../../client/__graphql__/queries/quiz';

export interface QuestionItem {
  id: number;
  locked: boolean;
  selected: boolean;
  question: string;
  options: QOptionItem[];
}

export interface QOptionItem {
  id: number;
  letter: string;
  label: string;
  correct: boolean;
  selected: boolean;
}

export const QuizTemplate_V3: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ area: string; urltag: string }>();
  const formRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [noMatch, setNoMatch] = React.useState(false);
  const thanksRef = React.useRef<any>(null);
  const [posted, setPosted] = React.useState(false);
  const [sale, setSale] = React.useState(true);
  const { submitWithDigger } = useDigtectiveContext();

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });
  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
          success
        }`
    }
  });

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
          success
          data {
            id
            address
            zip
            phone
            email
          }
        }`
    }
  });

  const [mail, { loading: mailLoading, error }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead && lead.mail && lead.mail.success) {
        setPosted(true);
      }
    }
  });

  const { data: pData, loading: pLoading } = useQuery(QUIZ_PAGE, {
    skip: !params?.urltag && !params?.area,
    fetchPolicy: 'network-only',
    variables: { input: { urltag: params?.urltag } }
  });

  const [lead, { error: leadError }] = useMutation<ILeadResponse, ILeadInput>(
    VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        setLoading(false);
        if (lead.valuation?.success) {
          setPosted(true);

          // GTM tracking

          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'Verdivurdering NY',
            gaCategory: 'Form',
            gaAction: 'Submit'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: '00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields = getFields(fields);
      setLoading(true);

      if (params.area === 'fjell' && params.urltag === 'loypa-til-solgt') {
        if (sale) {
          submitWithDigger({ zip: preparedFields.zip }, ({ diggerId }) => {
            lead({
              variables: {
                input: {
                  ...preparedFields,
                  diggerId,
                  referer: `${location.pathname}${location.search}`,
                  leadType: 'VERDIVURDERING',
                  pageType: 'GENERELL'
                }
              }
            });
          });
        } else {
          mail({
            variables: {
              input: {
                ...preparedFields,
                referer: `${location.pathname}${location.search}`,
                leadType: 'EPOST',
                mailType: 143
              }
            }
          });
        }
      } else if (
        params.area === 'sjo' &&
        params.urltag === 'loypa-til-solgt-2'
      ) {
        if (sale) {
          submitWithDigger({ zip: preparedFields.zip }, ({ diggerId }) => {
            lead({
              variables: {
                input: {
                  ...preparedFields,
                  diggerId,
                  referer: `${location.pathname}${location.search}`,
                  leadType: 'VERDIVURDERING',
                  pageType: 'GENERELL'
                }
              }
            });
          });
        } else {
          mail({
            variables: {
              input: {
                ...preparedFields,
                referer: `${location.pathname}${location.search}`,
                leadType: 'EPOST',
                mailType: 140
              }
            }
          });
        }
      }
    }
  });

  if (
    noMatch ||
    (params.area === 'fjell' && params.urltag === 'loypa-til-solgt-2') ||
    (params.area === 'sjo' && params.urltag === 'loypa-til-solgt')
  ) {
    return <NoMatch />;
  }

  return (
    <QuizPageWrapper>
      {params.area === 'fjell' && params.urltag === 'loypa-til-solgt' && (
        <Helmet>
          <script src="https://embed.vev.page/v1/WhJ1pnZyKD/pakFvF1vSua"></script>
        </Helmet>
      )}
      {params.area === 'sjo' && params.urltag === 'loypa-til-solgt-2' && (
        <Helmet>
          <script src="https://embed.vev.page/v1/dZ5MFuE5Hz/pakFvF1vSua"></script>
        </Helmet>
      )}
      <Seo
        title={pData?.getQuizPage?.seo?.title}
        description={pData?.getQuizPage?.seo?.content}
        image={pData?.getQuizPage?.seo?.image}
        url={location.pathname}
      />
      <Container
        style={{ ...pageFadeIn, paddingTop: '150px', paddingBottom: '100px' }}
      >
        <FormWrapper ref={formRef} style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h2" center={true}>
              Bli kontaktet
            </Heading>
            <Paragraph center={true} className="form-text">
              Vi har god dialog med hytteeiere i områdene vi opererer i. Det er
              en av grunnene til at vi kjenner fritidsmarkedet ekstra godt. Vår
              rolle som rådgiver er derfor svært verdifull for kundene våre som
              <br />
              selgere og kjøpere av fritidseiendom.
            </Paragraph>

            <CheckboxGroup style={{ padding: '2em 2em' }}>
              <Checkbox
                checked={sale}
                labelPos="right"
                label="Selge"
                onClick={() => setSale((prev) => !prev)}
              />
              <Checkbox
                checked={!sale}
                labelPos="right"
                label="Kjøp"
                onClick={() => setSale((prev) => !prev)}
              />
            </CheckboxGroup>
          </Section>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields.map((item) => {
              if (item) {
                return (
                  <FormRow key={`field_${item.name}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              }
            })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Kontakt meg
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig?.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
      </Container>

      <Thanks style={fadeIn}>
        {sale ? (
          <>
            <Section className="form-section">
              <Heading tag="h2" center={true}>
                Ta kontakt for kjøp av fritidseiendom
              </Heading>
              <AccordionGeneral />
            </Section>
            <Section className="form-section" style={{ paddingTop: 0 }}>
              <AccordionBenefits />
            </Section>
          </>
        ) : (
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        )}
      </Thanks>
    </QuizPageWrapper>
  );
};

const QuizPageWrapper = styled.div`
  width: 100%;
  min-height: 700px;

  .background-contain {
    height: 790px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const ButtonGridGroup = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;

  &.col-2 {
    grid-template-columns: max-content max-content;
    grid-column-gap: 20px;

    @media all and (max-width: 320px) {
      grid-template-columns: max-content;
      grid-column-gap: 0;
      grid-row-gap: 20px;
    }
  }
`;

export const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, max-content);
  grid-auto-flow: column;
  column-gap: 1em;
  justify-content: center;
`;
