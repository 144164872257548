import { createGlobalStyle } from 'styled-components';
import { fonts } from './fonts';
import rcslider from '../../common/components/ui/rc-slider/rc-slider';

export const GlobalStyles = createGlobalStyle`
    ${fonts}

    /*
      Remove logo in Cookiebot dialog
    */
      #CybotCookiebotDialogPoweredbyCybot {
      display: none !important;
    }



    * {
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }

    html {
        font-size: 100%;
    }

    html, body {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    body {
        background: ${({ theme }) => theme.colors.body};
        font-family: 'Avenir', sans-serif;
        font-size: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        line-height: 1.3;

        &.fixed-scroll {
            @media all and (max-width: ${(props) =>
              props.theme.mediaSizes.large}px) {
                overflow: hidden;
            }
        }

        &.fixed {
            position: fixed;
            overflow: hidden;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'DomaineDispCondRegular', sans-serif;
        font-weight: 400;
        margin-top: 0;
        color: ${({ theme }) => theme.colors.primary};
    }
    h1 {
        margin-top: 0;
        color: ${({ theme }) => theme.colors.primary};
        font-size: 2.8em;

        @media all and (min-width: 600px) and (max-width: 799px) {
            font-size: 3em;
        }

        @media all and (min-width: 800px) {
            font-size: 4em;
        }


    }
    h2 {
        font-size: 2em;
        @media all and (min-width: 600px) and (max-width: 899px) {
            font-size: 2.5em;
        }
        @media all and (min-width: 900px) {
            font-size: 3em;
        }
     }

     h3 {
        font-size: 1.5em;
        @media all and (min-width: 600px) and (max-width: 899px) {
            font-size: 2em;
        }
        @media all and (min-width: 900px) {
            font-size: 2.5em;
        }
     }

    h4 {
        font-size: 1.2em;
    }


    p {
        color: #fff;

        &.ingress {
            font-family: 'DomaineText-Regular', 'Times New Roman', serif;
            font-size: 1.1em;
        }
    }
    a {
        font-family: inherit;
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }

    ol, ul {
        color: #fff;
    }

    input, textarea, button {
        font-family: 'Avenir', sans-serif;
    }
    
    .header {
        background: ${({ theme }) => theme.colors.body};
        //box-shadow: 0 0 15px rgb(0 0 0 / 25%);
        .small-logo {
            margin-top: 0;
        }
        .large-logo {
            margin-top: 0;
        }
        form {
            position: relative;

            > div {
                top: 10px;
            }
        }
        .menu-search-icon {
            top: 0;
        }
        .menu-hamburger-icon {
            top: 5px;
        }
    }

    img {
        vertical-align: middle;
    }

    ${rcslider}
`;
